import { useEffect, useState } from "react"

import type { GnosisSafe } from "query/graphql"
import { fetcher } from "common/helpers/fetcher"
import { API_ROUTES } from "common/constants/routes"
import { getErrorMessage } from "governance/helpers/error"

type NextApiSafesResponse = {
  latestUpdate: string
  gnosisSafes: GnosisSafe[]
}

type Values = {
  isLoading: boolean
  data: NextApiSafesResponse | null
  message?: string
  refetch: () => void
}

type Props = {
  organizationId: string
}
export const useOrganizationSafeList = ({ organizationId }: Props): Values => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<Values["data"]>(null)
  const [message, setMessage] = useState<string | undefined>(undefined)

  const fetchSafes = (timestamp?: number) => {
    setIsLoading(true)
    setMessage(undefined)
    fetcher
      .rest<NextApiSafesResponse>({
        endpoint: API_ROUTES.safes(organizationId, timestamp),
        method: "GET",
        onError: (error) => {
          const errorMessage = getErrorMessage(error)

          if (errorMessage) setMessage(errorMessage)
        },
      })
      .then((data) => {
        if (!data) {
          setData(null)

          return
        }

        setData(data as Values["data"])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const refetch = () => {
    fetchSafes(Date.now())
  }

  useEffect(() => {
    fetchSafes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isLoading, data, refetch, message }
}
