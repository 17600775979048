import type { FC } from "react"
import type { StackProps } from "@chakra-ui/react"
import {
  AvatarGroup,
  MenuItem,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Flex,
  Text,
  Button,
  HStack,
  Skeleton,
  Box,
} from "@chakra-ui/react"
import truncate from "truncate"

import { ROUTES, EXTERNAL_ROUTES } from "common/constants/routes"
import type { ArrayElement } from "common/types/array"
import type { OrganizationSafeListQuery } from "query/graphql"
import { useUnlinkGnosisSafeMutation } from "query/graphql"
import { useOrganization } from "organization/providers/OrganizationProvider"
import Link from "common/components/Link"
import EmptyEntity from "common/components/EmptyEntity"
import { generateArrayOfNumbers } from "common/helpers/array"
import { useDevice } from "common/hooks/useDevice"
import { useModals } from "common/hooks/useModals"
import { EditMode } from "common/constants/edit-mode"
import type { Column } from "ui/components/Table"
import Table from "ui/components/Table"
import { getAccountIdParams } from "web3/helpers/accountId"
import { getChainIdParams } from "web3/helpers/chainId"
import { labelNumber } from "common/helpers/number"
import NetworkTag from "common/components/NetworkTag"
import { EllipsisVertical } from "ui/components/icons/font-awesome/EllipsisVertical"
import ExternalLink from "ui/components/icons/ExternalLink"
import { useOrganizationSafeList } from "organization/hooks/useOrganizationSafeList"
import UserAvatar from "common/components/UserAvatar"
import { sortAccountByPicture } from "common/helpers/sortByPicture"
import CardContainer from "common/components/CardContainer"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import { getDateFromNow } from "common/helpers/date"
import { chainIdToChainReference } from "web3/helpers/transformers"
import { useTokenBalances } from "common/hooks/useTokenBalances"

type GnosisSafes = OrganizationSafeListQuery["gnosisSafesV2"]
type GnosisSafe = ArrayElement<GnosisSafes>

type Props = {
  organizationId: string
  createSafeUrl?: string
  linkSafeUrl?: string
  editMode?: EditMode
  displaySigners?: boolean
  isWhiteLabel: boolean
}
const OrganizationSafeList: FC<Props & StackProps> = ({
  organizationId,
  createSafeUrl,
  linkSafeUrl,
  displaySigners = true,
  editMode = EditMode.Organization,
  isWhiteLabel,
  ...stackProps
}) => {
  const { isAdminRole } = useOrganization()
  const { onLargeDevice, onLittleDevice } = useDevice()

  const { data, message, refetch, isLoading } = useOrganizationSafeList({
    organizationId,
  })

  const gnosisSafes = data?.gnosisSafes ?? []

  const latestUpdate =
    data && data.latestUpdate ? getDateFromNow(data.latestUpdate) : null
  const hasGnosisSafes = gnosisSafes && gnosisSafes.length > 0
  const showButtons = isAdminRole && editMode !== EditMode.Governance

  const { addGnosisSafe } = useModals()

  return (
    <CardContainer
      isFullHeightView
      isTableView
      title="Safes"
      topRight={
        hasGnosisSafes && showButtons ? (
          <HStack>
            {createSafeUrl ? (
              <Box>
                <Link className="no-underline" href={createSafeUrl}>
                  <Button display={onLargeDevice} size="md" variant="secondary">
                    Create Safe
                  </Button>
                  <Button
                    display={onLittleDevice}
                    size="md"
                    variant="secondary"
                  >
                    Create
                  </Button>
                </Link>
              </Box>
            ) : null}
            {linkSafeUrl ? (
              <Box>
                <Link href={linkSafeUrl}>
                  <Button size="md" variant="secondary">
                    Link Safe
                  </Button>
                </Link>
              </Box>
            ) : null}
          </HStack>
        ) : null
      }
    >
      <Stack as="section" spacing={6} width="100%" {...stackProps}>
        <Box>
          {isLoading ? <SafesLoading /> : null}
          {!isLoading && hasGnosisSafes ? (
            <SafesList
              displaySigners={displaySigners}
              gnosisSafes={gnosisSafes}
              isWhiteLabel={isWhiteLabel}
              showDeleteButton={showButtons}
              onSuccess={refetch}
            />
          ) : null}

          {!isLoading && !hasGnosisSafes ? (
            <SafesEmptyList
              addSafe={addGnosisSafe.onOpen}
              createSafeUrl={createSafeUrl}
              editMode={editMode}
              isAdminRole={isAdminRole}
              linkSafeUrl={linkSafeUrl}
              message={message}
            />
          ) : null}

          {!isLoading && latestUpdate ? (
            <Flex flex={1} px={{ base: 3, sm: 5 }}>
              <Text mt={4} textStyle="body.regular.md">
                Latest update: {latestUpdate}.{" "}
                <Button variant="link" onClick={refetch}>
                  <Text color="gray.800" fontWeight="bold">
                    Refresh list
                  </Text>
                </Button>
              </Text>
            </Flex>
          ) : null}
        </Box>
      </Stack>
    </CardContainer>
  )
}

type SafesListProps = {
  gnosisSafes: GnosisSafe[]
  showDeleteButton: boolean
  onSuccess: () => void
  isWhiteLabel: boolean
  displaySigners: boolean
}
const SafesList: FC<SafesListProps> = ({
  gnosisSafes,
  showDeleteButton,
  onSuccess,
  isWhiteLabel,
  displaySigners,
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()
  const { mutate } = useUnlinkGnosisSafeMutation()

  const handleUnlinkGnosisSafe = (gnosisSafeId: string) => {
    mutate({ id: gnosisSafeId })
    onSuccess()
  }

  const generateLargeColumns = (
    gnosisSafes: GnosisSafe[],
    isWhiteLabel: boolean,
  ): Column[] => [
    {
      title: "Name",
      isNumeric: false,
      cells: gnosisSafes.map((safe, index) => {
        const { address } = getAccountIdParams(safe.id)
        const truncatedSafeAddress = address.slice(0, 5)

        return (
          <HStack key={`safe-table-name-${safe.id}-${index}`} spacing="1">
            {safe.name ? (
              <>
                <Link
                  href={
                    isWhiteLabel
                      ? getWhiteLabelRoute(ROUTES.safe.id(safe.id))
                      : ROUTES.safe.id(safe.id)
                  }
                >
                  <Text textStyle="body.bold.lg">{safe.name}</Text>
                </Link>
                <Text color="gray.500">{`(${truncatedSafeAddress})`}</Text>
              </>
            ) : (
              <Link
                href={
                  isWhiteLabel
                    ? getWhiteLabelRoute(ROUTES.safe.id(safe.id))
                    : ROUTES.safe.id(safe.id)
                }
              >
                <Text textStyle="body.bold.lg">{address}</Text>
              </Link>
            )}
          </HStack>
        )
      }),
    },
    {
      title: "Total Balance",
      isNumeric: false,
      cells: gnosisSafes.map((safe, index) => {
        // TODO: temporary return n/a while balances unavailable

        return <SafeBalance key={index} index={index} safe={safe} />
      }),
    },
    ...(displaySigners
      ? [
          {
            title: "Signers",
            isNumeric: false,
            cells: gnosisSafes.map((safe, index) => {
              return (
                <HStack key={`safe-table-signers-${safe.id}-${index}`}>
                  {safe?.owners ? (
                    <AvatarGroup fontSize="xs" size="sm">
                      {safe.owners
                        .sort((a, b) => sortAccountByPicture(a, b))
                        .map((owner, index) => (
                          <Link
                            key={index}
                            className="no-underline"
                            href={ROUTES.profile(owner.address)}
                          >
                            <UserAvatar
                              _hover={{ size: "md" }}
                              address={owner.address}
                              size="sm"
                              src={owner.picture}
                            />
                          </Link>
                        ))}
                    </AvatarGroup>
                  ) : (
                    <Text
                      color="gray.500"
                      textStyle="body.bold.lg"
                      w="fit-content"
                    >
                      N/A
                    </Text>
                  )}
                </HStack>
              )
            }),
          },
        ]
      : []),
    {
      title: "Network",
      isNumeric: false,
      cells: gnosisSafes.map((safe, index) => {
        const { chainId } = getAccountIdParams(safe.id)

        return (
          <NetworkTag
            key={`safe-table-network-${safe.id}-${index}`}
            chainId={chainId}
          />
        )
      }),
    },
    {
      title: "",
      isNumeric: true,
      cells: gnosisSafes.map((safe, index) => {
        const { chainId, address } = getAccountIdParams(safe.id)
        const { reference } = getChainIdParams(chainId)
        const blockExplorerLink = EXTERNAL_ROUTES.etherscan.address(
          reference,
          address,
        )

        return (
          <Flex key={`safe-table-button-${safe.id}-${index}`} justify="end">
            <Menu>
              <MenuButton
                _active={{ background: "transparent" }}
                _hover={{ background: "transparent" }}
                as={Button}
                bgColor="white"
                minW="0"
                px={0}
              >
                <Icon as={EllipsisVertical} fill="gray.600" />
              </MenuButton>
              <MenuList maxW="10">
                <Link
                  isExternal
                  href={blockExplorerLink}
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem>
                    <HStack align="center" spacing={2}>
                      <Text textStyle="body.bold.md">Block explorer</Text>
                      <Icon as={ExternalLink} h={3} w={3} />
                    </HStack>
                  </MenuItem>
                </Link>
                {showDeleteButton && (
                  <MenuItem onClick={() => handleUnlinkGnosisSafe(safe.id)}>
                    <Text textStyle="body.bold.md">Unlink safe</Text>
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Flex>
        )
      }),
    },
  ]

  const generateLittleColumns = (
    gnosisSafes: GnosisSafe[],
    isWhiteLabel: boolean,
  ): Column[] => [
    {
      title: "Name",
      isNumeric: false,
      cells: gnosisSafes.map((safe, index) => {
        const { address } = getAccountIdParams(safe.id)
        const truncatedSafeAddress = address.slice(0, 5)

        return (
          <HStack key={`safe-table-name-${safe.id}-${index}`} spacing="1">
            {safe.name ? (
              <>
                <Link
                  href={
                    isWhiteLabel
                      ? getWhiteLabelRoute(ROUTES.safe.id(safe.id))
                      : ROUTES.safe.id(safe.id)
                  }
                >
                  <Text color="gray.700" textStyle="body.bold.lg">
                    {truncate(safe.name, 10)}
                  </Text>
                </Link>
              </>
            ) : (
              <Link
                href={
                  isWhiteLabel
                    ? getWhiteLabelRoute(ROUTES.safe.id(safe.id))
                    : ROUTES.safe.id(safe.id)
                }
              >
                <Text textStyle="body.bold.lg">{truncatedSafeAddress}</Text>
              </Link>
            )}
          </HStack>
        )
      }),
    },
    {
      title: "Total Balance",
      isNumeric: false,
      cells: gnosisSafes.map((safe, index) => {
        return <SafeBalance key={index} index={index} safe={safe} />
      }),
    },

    {
      title: "",
      isNumeric: true,
      cells: gnosisSafes.map((safe, index) => {
        const { chainId, address } = getAccountIdParams(safe.id)
        const { reference } = getChainIdParams(chainId)
        const blockExplorerLink = EXTERNAL_ROUTES.etherscan.address(
          reference,
          address,
        )

        return (
          <Flex key={`safe-table-button-${safe.id}-${index}`} justify="end">
            <Menu>
              <MenuButton
                _active={{ background: "transparent" }}
                _hover={{ background: "transparent" }}
                as={Button}
                bgColor="white"
                minW="0"
                px={0}
              >
                <Icon as={EllipsisVertical} fill="gray.600" />
              </MenuButton>
              <MenuList maxW="10">
                <Link
                  isExternal
                  href={blockExplorerLink}
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem>
                    <HStack align="center" spacing={2}>
                      <Text textStyle="body.bold.md">Block explorer</Text>
                      <Icon as={ExternalLink} h={3} w={3} />
                    </HStack>
                  </MenuItem>
                </Link>
                {showDeleteButton && (
                  <MenuItem onClick={() => handleUnlinkGnosisSafe(safe.id)}>
                    <Text textStyle="body.bold.md">Unlink safe</Text>
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Flex>
        )
      }),
    },
  ]

  const largeColumns = generateLargeColumns(gnosisSafes, isWhiteLabel)
  const littleColumns = generateLittleColumns(gnosisSafes, isWhiteLabel)

  return (
    <Stack spacing={2}>
      <Flex w="full">
        <Table
          columns={largeColumns}
          display={onLargeDevice}
          displayBorderTop={false}
          emptyMessage="There are no safes to show"
          id="governance-gnosis-safes"
        />
        <Table
          columns={littleColumns}
          display={onLittleDevice}
          displayBorderTop={false}
          emptyMessage="There are no safes to show"
          id="gnosis-safes"
        />
      </Flex>
    </Stack>
  )
}

type SafeBalanceProps = {
  safe: GnosisSafe
  index: number
}

const SafeBalance: FC<SafeBalanceProps> = ({ safe, index }) => {
  const { chainId, address } = getAccountIdParams(safe.id)

  const chainReference = chainIdToChainReference(chainId)

  const { tokenBalances } = useTokenBalances(chainReference, address)

  const balance = tokenBalances?.tokens
    ? tokenBalances?.tokens?.reduce((acc, token) => {
        return acc + (Number(token?.tokenInfo?.fiat) || 0)
      }, 0)
    : 0

  // add native asset
  const totalBalance =
    (balance || 0) + (tokenBalances?.nativeAsset?.tokenInfo?.fiat || 0)

  const hasPriceData =
    Boolean(tokenBalances) &&
    tokenBalances?.nativeAsset?.tokenInfo?.price.rate !== null

  return (
    <Text
      key={`safe-table-balance-${safe.id}-${index}`}
      color="gray.700"
      textStyle="body.bold.lg"
    >
      {hasPriceData ? labelNumber(totalBalance) : null}
      {hasPriceData ? (
        <Text
          as="span"
          color="gray.500"
          textStyle="body.regular.large"
          w="fit-content"
        >
          {" "}
          USD
        </Text>
      ) : (
        <Text
          as="span"
          color="gray.500"
          textStyle="body.regular.large"
          w="fit-content"
        >
          N/A
        </Text>
      )}
    </Text>
  )
}

type SafesEmptyListProps = {
  isAdminRole: boolean
  editMode?: EditMode
  addSafe: () => void
  createSafeUrl?: string
  linkSafeUrl?: string
  message?: string
}
const SafesEmptyList: FC<SafesEmptyListProps> = ({
  isAdminRole,
  addSafe,
  createSafeUrl,
  linkSafeUrl,
  message,
  editMode = EditMode.Organization,
}) => {
  const { onLargeDevice } = useDevice()
  const showButtons = isAdminRole && editMode !== EditMode.Governance

  return (
    <>
      {editMode === EditMode.Governance ? (
        <Flex
          align="center"
          bg="gray.50"
          direction="column"
          h={16}
          justify="center"
          mx={4}
        >
          <Text color="gray.600" textStyle="body.bold.md">
            {message || "No safes found"}
          </Text>
        </Flex>
      ) : (
        <Stack align="center" direction="column" pb={8} pt={3} spacing={2}>
          <EmptyEntity display={onLargeDevice} title="" />
          <Text align="center" textStyle="body.bold.xl">
            This DAO hasn&apos;t added a Safe yet
          </Text>
          <Text align="center" textStyle="body.regular.md">
            Any Tally metadata admin of this DAO can link a Safe
          </Text>
          {showButtons ? (
            <Stack>
              {createSafeUrl ? (
                <Box pt={4}>
                  <Link className="no-underline" href={createSafeUrl}>
                    <Button size="md" variant="primary">
                      Create Safe
                    </Button>
                  </Link>
                </Box>
              ) : null}
              {linkSafeUrl ? (
                <Box pt={4}>
                  <Link href={linkSafeUrl}>
                    <Button size="md" variant="primary" onClick={addSafe}>
                      Link Safe
                    </Button>
                  </Link>
                </Box>
              ) : null}
            </Stack>
          ) : null}
        </Stack>
      )}
    </>
  )
}

const SafesLoading = () => {
  return (
    <Stack px={4} py={3} spacing={3}>
      {generateArrayOfNumbers(6).map((number) => (
        <Skeleton key={`safe_card_skeleton_${number}`} h="3rem" w="full" />
      ))}
    </Stack>
  )
}

export default OrganizationSafeList
