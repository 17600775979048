type Owner = {
  picture?: string | null | undefined
}

export const sortAccountByPicture = (a: Owner, b: Owner): number => {
  if (a.picture && b.picture) {
    return 1
  }

  if (a.picture && b.picture) {
    return -1
  }

  return 0
}
