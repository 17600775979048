export enum AssetType {
  NATIVE,
  ERC20,
}

type Price = {
  rate: number
  rate24H: number
}

export type TokenInfo = {
  address: string
  decimals: string
  price: Price
  symbol: string
  logo: string
  name: string
  balance24H: string | number
  fiat: number
  fiat24H: number
  isDust: boolean
}

export type TokenBalance = {
  type: AssetType
  rawBalance: string
  tokenInfo: TokenInfo
  fiat?: number
}

export type TokenBalances = {
  nativeAsset: TokenBalance
  tokens?: TokenBalance[]
}
