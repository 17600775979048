import type { FC, ReactElement } from "react"
import React, { useMemo } from "react"
import type {
  FlexProps,
  TableRowProps,
  TableCellProps,
  TableProps,
  TableColumnHeaderProps,
} from "@chakra-ui/react"
import {
  Table as ChakraTable,
  Thead,
  Flex,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
} from "@chakra-ui/react"

import { generateArrayOfNumbers } from "common/helpers/array"

export type Column = {
  title: string
  isNumeric: boolean
  centerTitle?: boolean
  cells: ReactElement[]
  minW?: TableColumnHeaderProps["minW"]
}

type Props = {
  id: string
  columns: Column[]
  emptyMessage: string
  footer?: ReactElement
  displayTableHeader?: boolean
  tableRowProps?: TableRowProps
  tableHeaderProps?: TableCellProps
  tableProps?: TableProps
  displayBorderTop?: boolean
}

const Table: FC<Props & FlexProps> = ({
  columns,
  id,
  footer,
  displayTableHeader = true,
  emptyMessage,
  tableRowProps,
  tableHeaderProps,
  tableProps,
  displayBorderTop = true,
  ...flexProps
}) => {
  const cellsCount = useMemo(() => columns?.[0]?.cells?.length ?? 0, [columns])
  const rows = useMemo(
    () =>
      generateArrayOfNumbers(cellsCount)?.reduce<ReactElement[][]>(
        (acc, _, index) => {
          const row = columns.map((column) => column.cells[index])

          return [...acc, row]
        },
        [],
      ),
    [cellsCount, columns],
  )
  const hasRows = Boolean(rows.length)

  return (
    <Flex align="stretch" direction="column" w="full" {...flexProps}>
      <ChakraTable direction="column" rounded="sm" {...tableProps}>
        {hasRows && displayTableHeader ? (
          <Thead>
            <Tr
              borderBottom="gray.light"
              borderTop={displayBorderTop ? "gray.light" : ""}
            >
              {columns?.map(
                ({ title, isNumeric, minW, centerTitle = false }, index) => (
                  <Th
                    key={`table-header-${id}-${index}`}
                    borderColor="gray.100"
                    isNumeric={isNumeric}
                    minW={minW ? minW : { base: "auto", lg: 24 }}
                    px={{ base: 3, lg: 6 }}
                    py={3}
                    {...tableHeaderProps}
                  >
                    {centerTitle ? (
                      <Text
                        align="center"
                        textStyle="body.regular.sm"
                        w="inherit"
                      >
                        {title}
                      </Text>
                    ) : (
                      <Text textStyle="body.regular.sm" w="inherit">
                        {title}
                      </Text>
                    )}
                  </Th>
                ),
              )}
            </Tr>
          </Thead>
        ) : null}
        <Tbody>
          {rows?.map((row, index) => (
            <Tr
              key={`table-row-${id}-${index}`}
              borderBottom="gray.light"
              borderTop={displayTableHeader ? "gray.light" : ""}
              {...tableRowProps}
            >
              {row.map((content, index) => (
                <Td
                  key={`table-cell-${id}-${index}`}
                  borderColor="gray.100"
                  px={{ base: 3, lg: 6 }}
                  py={3}
                >
                  {content}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
      {!hasRows ? (
        <>
          <Flex
            align="center"
            bg="gray.50"
            h={16}
            justify="center"
            mt={2}
            mx={4}
          >
            <Text color="gray.600" textStyle="body.bold.md">
              {emptyMessage}
            </Text>
          </Flex>
        </>
      ) : null}
      {hasRows && footer != null ? (
        <Flex borderBottom="gray.50" borderX="gray.50" w="full">
          {footer}
        </Flex>
      ) : null}
    </Flex>
  )
}

export default Table
